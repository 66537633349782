export default {
    data: () => ({
        title: "Instituto Ideias - Santa Helena - PR",
        description: "Instituto Ideias - Instituto de Desenvolvimento Econômico e Integração Autossustentável de Santa Helena",
        author: "VRCLIC - Soluções Digitais",
        generator: "vrclic"
    }),
    methods: {
        setMeta(name, content) {
            let el = document.querySelector(`meta[property="${name}"]`);

            if (!el) {
                el = document.createElement('meta');
                el.setAttribute("property", name);
                document.head.appendChild(el);
            }

            el.setAttribute("content", content);
        },
        setTitle(content) {
            let title = document.querySelector("title");

            if (!title) {
                title = document.createElement("title");
                document.head.appendChild(title);
            }

            title.innerHTML = content;
        }
    }
}