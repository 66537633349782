<template>
  <div id="nav">
    <top-menu></top-menu>
  </div>
  <router-view />
  <FooterMenu />
  <!-- <HeaderSeoComponent/> -->
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import FooterMenu from '@/components/FooterMenu.vue'
// import HeaderSeoComponent from "@/components/HeaderSeoComponent.vue";
import seo from './mixins/seo';
export default {
  name: 'App',
  mixins: [seo],
  components: {
    TopMenu, FooterMenu
  }
}
</script>
